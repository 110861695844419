import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Download`}</h2>
    <p><a parentName="p" {...{
        "href": "/6c9c2a3fdfcff9e0331633181df6e785/Nokia-Lumia-920.zip"
      }}>{`Download here!`}</a></p>
    <h2>{`About`}</h2>
    <p>{`Following up on my previous `}<a parentName="p" {...{
        "href": "http://www.pedrolamas.pt/windows-phone/nokia-lumia-800-skin-en/"
      }}>{`Nokia Lumia 800 skin`}</a>{` for Windows Phone emulator, I now bring you my very own Nokia Lumia 920 skin, and the result is what you can see below! :)`}</p>
    <p><a parentName="p" {...{
        "href": "/4e58e73f0514e930b39e64573f64fee7/Nokia-Lumia-920.png"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "150px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "179.99999999999997%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAEWElEQVRIx6WU7U9bVRzHfy339OE+tBSwjFJKoaX3lvb2ttxLKSu0FCpZGVCKqLQsWeKLJTqMEg1oUDMe5/Rv8IXJXuiismQ8rMCQ+WaJDhmuumQwKHTRLBuGuZnF+EJzixLQYaB+8815cc/5nO895/zOga17H2w96EmujW4kBzeSQ6nkUGptaGNtMJUcurs+fHd9eGNtcH11YLtN3jmTvHNmIzn6aPNUau0C3Py6+aWT5nBbMBKpjbTVtoVrwq3e1hZvU1NVMOgKNrhCx9wtLd5QyB0KuZtC7tAxweer6nudurXUD/HxTo5zNDa2BAKNgUBjXd2zfn/Q52uorvbxvKeC93g8td6agCBUx2Inu7t7Oju7vDXHj3qeWbo+DHPTnQxTlJ2tIQicJAmCwLdNkgRFEekvShwXTeBKgsCVSnm2pqAhkLu0MATzs9HiYi2GyeAAkkgkACCTa3w16pvfDsH8TKdOp0FIvt0JUqloiUQc+DQBAIbURz1E4sYwXJ3uJPNIm1w2qYaxbMmYAj5XwKwGTijE2aR7M/V6vd9fi5C6kpcnFkdEGFPJPAr0mxZ+VsFjb/lDr/WPXHiXEEdn7YXz8/N53iWVkrwLzUz0iHAWhdy44j4O95p9T+ZnHn7y8aN8aS8Ou+FdywYARYUTTYydFmEpKcIPZLA10v9kLr753huPc6APl/wblkgkUqkEQOHisKmL3XA1HpWSqEqBftWhTduRX8J199nC37XQT0r/I9nFocmLr8J8PIqRyCnDEpxxQShbsBZ8w5eteJhXNOKOZUn2gR1o4otumLscpSiEMKQE2DEOgPY9ahF2OrBrX70Dc5djFIWyshAcTDvwD0vn4MpUjCKxLOyQMIuWFkYyh+OXeuDKZIw8PMyx6NJn3SJMkSiD5PnpXpid7MoA5liUWHwfZidimcHfLZz9/zCVKTwz0UVRsgw2bDu5K4PkvfBhy5OV7cCyw9e27K81q1SHgWH7SsoTi+fEIlGr5Rgm2//B/OfrCaDgnYov430irMlWHjw5LaXgwi+cPyX+tlarysvTqtXq7F1Sq9UEQVAUpfpbVFokSSjx3EqeGPv0ZZgejxoM2srKKkEQnE4nx3GOtGw2m9fr5XmeYRir1cowjMVioWnaZrPq9Ra3QF6/NgAzEzGj8UhFheD3+x0OB8uy9rRomo5Go/X19SaTiWEYmqbNZjPLsoJQoSs0e9xU+qgmY2azrrJScDpZl8vhcjmcTpbj7Olsxm632u1Wm40pL6etVgvDlNG0yWi0eD2qpYVRmJ06oSssspa7LbSzzCLaXMaZzI5SE1tSajeW2IwltmJjuaHYWmRg9EV0kcGSk8dUuDSJG2dh9fuON0/ntIeZFztML7SLfj5S2tFW8lzY2B4ujrQYIs2Gtuai8HF9a1NhS0jXGioIBgo/HJQvJwYgtfrRT6uvpZbfSi33ppb7Uiu9qZXt9qnu21ju+3H17dTt7tu3xv8ElTBt6d6EqMMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Nokia Lumia 920",
            "title": "Nokia Lumia 920",
            "src": "/static/81f96eaaec16b77bbfb8346fb3f5dd33/8a4e8/Nokia-Lumia-920-thumb.png",
            "srcSet": ["/static/81f96eaaec16b77bbfb8346fb3f5dd33/8a4e8/Nokia-Lumia-920-thumb.png 150w"],
            "sizes": "(max-width: 150px) 100vw, 150px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`This skin uses hi-res images, and has the following fully working buttons:`}</p>
    <ul>
      <li parentName="ul">{`Back/App switcher`}</li>
      <li parentName="ul">{`Start/Speech`}</li>
      <li parentName="ul">{`Search`}</li>
      <li parentName="ul">{`Volume Up`}</li>
      <li parentName="ul">{`Volume Down`}</li>
      <li parentName="ul">{`Camera`}</li>
    </ul>
    <p>{`Note: though the “Power” is present and does the expected animation(!), this doesn’t seem to work on the Windows Phone emulator!`}</p>
    <h2>{`Install`}</h2>
    <p>{`Here’s what you need to do to use this skin:`}</p>
    <ul>
      <li parentName="ul">{`Make sure the emulator is closed!`}</li>
      <li parentName="ul">{`Open the emulator folder (normally should be something like “C:\\Program Files (x86)\\Microsoft XDE\\1.0\\”)`}</li>
      <li parentName="ul">{`Create a backup of the “WM7_Skin.xml” file just in case you want to go back to the old skin`}</li>
      <li parentName="ul">{`Extract the files to the emulator folder (note: requires administrator access)`}</li>
      <li parentName="ul">{`Start the emulator!`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      